import React, { useState } from "react";
import './App.css';
import ClipboardJS from "clipboard";

function generateRandomString() {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

function shortenURL(userUrl, token, apiKey) {
  return fetch("https://tida.ly/", {
    method: "POST",
    body: JSON.stringify({
      url: userUrl,
      token: token,
    }),
    headers: {
      "content-type": "application/json",
      "x-api-key": apiKey,
    },
  })
    .then((res) => {
      if (res.status == 403) {
        throw new Error(`Authorization failed! Ensure and check the API key.`);
      } else if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return "https://tida.ly/" + token; 
    })
    .catch((error) => {
      return error.message;
    });
}

function Results({ response }) {
  var result;
  if (response.startsWith("http")) {
    result = (
      <div className="results">
        <p id="share">
          <a href={response}>{response}</a>
        </p>
        <div className="clipboard-btn" data-clipboard-target="#share">
          📝Copy
        </div>
      </div>
    );
  } else {
    result = <p className="error">{response}</p>;
  }
  return result;
}

function App() {
  const [userUrl, updateURL] = useState("");
  const [token, updateToken] = useState(generateRandomString);
  const [apiKey, updateApiKey] = useState("");
  const [response, setResponse] = useState("");

  React.useEffect(() => {
    new ClipboardJS(".clipboard-btn");
  }, []);

 const handleClick = async () => {
   const res = await shortenURL(userUrl, token, apiKey);
   setResponse(res);
 };

 return (
   <div className="App">
     <header className="App-header">
       <img
         src="https://assets.tidalcloud.com/tidal-logos/Tidal+Company+Logos/tidal-logo-rgb-horizontal.png"
         className="logo"
         alt="logo"
       />
       <div className="input-box">
         <input
           type="text"
           className="input"
           value={userUrl}
           placeholder="Your URL"
           onChange={(e) => updateURL(e.target.value)}
         />
         <input
           type="text"
           className="input"
           value={token}
           placeholder="Shortened URL"
           onChange={(e) => updateToken(e.target.value)}
         />
         <input
           type="text"
           className="input"
           value={apiKey}
           placeholder="API Key"
           onChange={(e) => updateApiKey(e.target.value)}
         />
       </div>
       <button className="button" onClick={handleClick}>
         Shorten URL
       </button>
       <Results response={response} />
       <p className="footer">© Tidal SW Inc. 2023</p>
     </header>
   </div>
 );
}

export default App;